<template>
	<div class="main">
		<home-login v-if="!access" @onSubmit="checkPw" />
		<skip-nav v-if="access" />
		<main-header v-if="access" />
		<home-hero v-if="access" />
		<home-doctors class="nav-anchor" id="for_practices" role="main" v-if="access" />
		<home-how-doctors role="complementary" aria-label="How It Works for Doctors" v-if="access" />
		<home-patients class="nav-anchor" id="for_patients" role="complementary" aria-label="For Patients" v-if="access" />
		<home-app class="nav-anchor" id="the_app" role="complementary" aria-label="How The App Works" v-if="access" />
		<home-why class="nav-anchor" id="why" role="complementary" aria-label="Improve Office Efficiency" v-if="access" />
		<faq class="nav-anchor" id="faq" role="complementary" aria-label="Frequently Asked Questions" v-if="access" />
		<main-footer v-if="access" />
		<demo-form v-if="access" />
		<div class="main__refresh" role="alertdialog" v-if="evt">
			<div class="main__refresh-content">NEW VERSION AVAILABLE! Please refresh this page to see the latest updates! <a href="#" @click.prevent="refresh">REFRESH NOW</a></div>
		</div>
	</div>
</template>

<script>
const ACCESS = process.env.ACCESS || process.env.VUE_APP_ACCESS

// @ is an alias to /src
import SkipNav from '@/components/SkipNav'
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
import Faq from '@/components/Faq'
import HomeHero from '@/components/home/HomeHero'
import HomePatients from '@/components/home/HomePatients'
import HomeDoctors from '@/components/home/HomeDoctors'
import HomeApp from '@/components/home/HomeApp'
import HomeHowDoctors from '@/components/home/HomeHowDoctors'
import HomeWhy from '@/components/home/HomeWhy'
import HomeLogin from '@/components/home/Login'
import DemoForm from '@/components/home/DemoForm'

export default {
	name: 'home',
	components: {
		SkipNav,
		MainHeader,
		MainFooter,
		HomeHero,
		HomePatients,
		HomeDoctors,
		HomeApp,
		HomeHowDoctors,
		Faq,
		HomeLogin,
		HomeWhy,
		DemoForm,
	},
	data: () => ({
		fields: {},
		pw: 'qp4lyfe',
		access: ACCESS,
		evt: null,
	}),
	created() {
		document.addEventListener('SWUpdated', (evt) => {
			this.evt = evt
		})
	},
	methods: {
		checkPw(fields) {
			if (fields.pw == this.pw) {
				this.access = true
			}
		},
		refresh() {
			if (!this.evt) return
			this.evt.detail.postMessage({ action: 'skipWaiting' })
		},
	},
};
</script>

<style scoped lang="scss">
.main {
	position: relative;

	&__refresh {
		align-items: center;
		background-color: rgba(color_('background', 'dark'), 0.95);
		bottom: 1rem;
		border-radius: 5px;
		color: white;
		display: flex;
		font-size: 14px;
		justify-content: center;
		left: 1rem;
		max-width: 100%;
		padding: 1em;
		position: fixed;
		// width: 100%;
		z-index: 900;

		a {
			margin-left: 1em;
			margin-right: 1em;
		}
	}
}
</style>
