<template>
    <div class="doctors">
        <div class="doctors__content">
            <div class="doctors__content-row">
                    <h2 class="doctors__text-heading">For Practices</h2>
            </div>
            <div class="doctors__content-row">
                <div class="doctors__benefits">
					 <div class="doctors__benefits-benefit">
                        <img src="@/assets/images/icon_trend.svg" alt="Patient Forms" height="25" width="25"/>
						<h3>Improve efficiency and accuracy to see more patients and increase revenue</h3>
						<p>Streamlining your patient intake workflow with a contactless check-in solution can maximize appointments and minimize data inaccuracies that lead to costly denial write-offs, while making patients feel safer helps you increase and recover lost revenue.</p>
                    </div>

                    <div class="doctors__benefits-benefit">
                        <img src="@/assets/images/icon_car.svg" alt="Secure" height="25" width="25"/>
						<h3>Maintain safety with contactless, curbside and telehealth check-in</h3>
						<p>Help ensure the safety of your patients and staff while benefitting from a faster and more accurate check-in process. Many patients are generally avoiding healthcare offices in order to maintain social distances. Offer them the added safety and convenience of our contactless check-in solution.</p>
                    </div>
					<div class="doctors__benefits-benefit">
                        <img src="@/assets/images/icon_nousers.svg" alt="History" height="25" width="25"/>
                        <h3>A zero patient waiting room is the new process for safety</h3>
                        <p>Most practice offices were not designed with social distancing in mind. This can create a lot of inconvenience and discomfort for your patients and staff. By allowing patients to check in from outside your office and incorporating our tools to allow your staff to communicate directly with patients, you can easily maintain a clean and healthy office environment.</p>
                    </div>
                    <div class="doctors__benefits-benefit">
                        <img src="@/assets/images/icon_secure.svg" alt="End-to-End Encryption" height="25" width="25"/>
                        <h3>End-to-End encryption ensures secure data transfer</h3>
                        <p>Patient information is transferred to your practice over a secure connection using End-to-End encryption so your team can validate the patient information, print it in a clean and legible format, or even import directly into your EMR/EHR. This means only you and your patient can read the patient profile information, including COVID-19 screening information, insurance information, medical history and time-stamped digitally signed legal forms.</p>
                    </div>

                    <div class="col-md-12 mt-4 mb-5">
                        <a href="https://5inline.typeform.com/to/WsOgcy" @click.prevent="bus.$emit('demoForm')" class="doctors__button">REQUEST A DEMO FOR YOUR PRACTICE</a>
                    </div>

                </div>
                <div class="doctors__img">
                    <img src="@/assets/images/fordoctors_2.jpg" width="600" height="764" alt="Quick Patient Mobile App for doctors" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from '@/lib/bus'

export default {
  name: 'HomeDoctors',
  data: () => ({
	  bus,
  }),
};
</script>

<style scoped lang="scss">
.doctors {
    @include gutter('padding-top', 3);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-down(sm) {
		@include gutter('padding-top', 2);
        @include gutter('padding-bottom', 2);
    }

    &__content {
        @include make-container();
        @include make-container-max-widths;

        &-row {
            @include make-row();
            align-items: flex-start;

			@include media-breakpoint-up(md) {
				@include gutter('margin-bottom', 2);
			}

            @include media-breakpoint-down(sm) {
                @include gutter('padding', .5);
            }

            &--negative-margin {
                @include media-breakpoint-up(sm) {
                    @include gutter('margin-bottom', -2);
                }
            }
        }
    }

    &__text {
        &-subheading {
            @include gutter('margin-bottom', 0.5);
            @include make-col-ready();
            font-size: $font-size-base;
            display: block;

            @include media-breakpoint-up(md) {
                @include make-col(8);
            }
        }

        &-heading {
            font-weight: 600;
            font-size: $font-size-base * 3;
            font-family: $headings-font-family;
            color: color_('primary');
            width: 100%;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: $font-size-base * 3.4;
            }
        }
    }

    &__img {
        @include make-col-ready();

        @include media-breakpoint-up(md) {
            @include make-col(4);
        }

        img {
            display: none;

            @include media-breakpoint-up(md) {
                transform: translateX(5%);
                display: block;
            }
        }
    }

    &__benefits {
        @include make-col-ready();
        @include gutter('padding-top', 1.5);
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @include media-breakpoint-up(md) {
            @include make-col(8);
            text-align: left;
        }

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        &-benefit {
            @include make-col-ready();
            @include gutter('margin-bottom', 1.5);
            align-self: flex-start;

            @include media-breakpoint-up(md) {
                @include make-col(6);
            }

            img {
                @include gutter('margin-bottom', .75);
            }

            h3 {
                font-family: $font-family-sans-serif;
                @include fontSize(20px);
                @include gutter('margin-bottom', .5);
                line-height: 1.5;
                font-weight: 600;
            }

            p {
                @include fontSize(16px);
                line-height: 1.5;
            }
        }
    }

    &__button {
        background: color_('primary');
        border-radius: $border-radius;
        color: color_('white');
		display: inline-block;
        text-align: center;
        margin: 0 auto;
        @include fontSize(18px);
        font-weight: 700;
        letter-spacing: 0.15em;
        @include gutter('padding-top', .75);
        @include gutter('padding-bottom', .75);
        @include gutter('padding-left', 1.5);
        @include gutter('padding-right', 1.5);
        transition: all 150ms $easing-cubic;

        &:hover {
            color: color_('white');
            background-color: lighten(color_('primary'), 5);
            text-decoration: none;
        }

        img {
            position: relative;
            top: -4px;
            left: 10px;
        }
    }
}
</style>
