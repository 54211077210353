<template>
	<div class="skipnav" role="navigation" aria-label="Skip Menu">
		<a href="#for_practices">Skip to main content</a>
	</div>
</template>

<script>
export default {
	name: 'SkipNav',
}
</script>

<style scoped lang="scss">
.skipnav {
	font-size: 15px;
	left: 0;
	position: fixed;
	top: 0;
	z-index: 990;
	width: 100%;

	a {
		@include gutter('padding-left');
		background-color: color_('primary');
		color: color_('white');
		display: inline-block;
		height: 0px;
		left: -10000px;
		overflow: hidden;
		pointer-events: none;
		width: 1px;

		&:focus,
		&:active {
			height: auto;
			left: 0;
			overflow: visible;
			pointer-events: all;
			position: static;
			width: 100%;
		}
	}
}
</style>
