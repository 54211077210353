<template>
    <div class="app">
        <div class="app__content">
            <div class="app__content-row mb-2">
                <div class="app__content-text">
                    <h2>The App</h2>
                    <p>Free for Patients on iOS + Android</p>
                </div>
                <div class="app__tour-item">
                    <img src="@/assets/images/app_1.jpg" alt="Create Profile on Phone" height="auto" width="100%"/>
                    <h3>Create patient profiles for you and your family members on your mobile device, on your own time with no pressure.</h3>
                    <p>Fill out your patient profile information once. Save it, come back later to update or change it. Create profiles for your family so you never have to remember personal information like social security numbers or medications.</p>
                </div>
                <div class="app__tour-item">
                    <img src="@/assets/images/app_2.jpg" alt="Transmit Profile from Phone" height="auto" width="100%"/>
                    <h3>At your doctor's office, securely transmit your patient profile containing accurate, up-to-date information.</h3>
                    <p>When you get to your doctor's office, open the Quick Patient app on your mobile device. Scan the QR code outside or at the front desk. Select who you're checking in and send. That's it. Sit back and relax.</p>
                </div>
                <div class="app__tour-item">
                    <img src="@/assets/images/app_3.jpg" alt="Receive Profile at Doctor Office" height="auto" width="100%"/>
                    <h3>Your doctor's front office will instantly and securely receive your patient profile and check you in!</h3>
                    <p>The patient administrator at your doctor's office will receive your patient information, including photos and signatures. No worrying about reading handwriting or inaccurate information. They know exactly who you are and when you checked in.</p>
                </div>
            </div>
            <div class="app__content-row mb-5" v-if="enableApp">
                <a href="#" class="app__button">DOWNLOAD THE APP <img src="@/assets/images/icon_phone.svg" height="25" width="25" alt="Download App" /></a>
                <div class="app__download">
                    <a href="#"><img src="@/assets/images/download_android.svg" alt="Google Play Store" height="70" width="190"/></a>
                    <a href="#"><img src="@/assets/images/download_ios.svg" alt="Apple App Store" height="70" width="190"/></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeApp',
    data: () => ({
		enableApp: false,
	}),
};
</script>

<style scoped lang="scss">
.app {
    @include gutter('padding-top', 3);
    @include gutter('padding-bottom', 2.5);
    background-color: color_('background');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-down(sm) {
		@include gutter('padding-top', 0);
        @include gutter('padding-bottom', 1);
    }

    &__content {
        @include make-container();
        @include make-container-max-widths;

        &-row {
            @include make-row();
            @include gutter('margin-bottom', 2);
            align-items: center;

            &--no-margin {
                @include gutter('margin-bottom', 0);
            }
        }

        &-text {
            @include make-col-ready();
            @include make-col(12);
            @include gutter('margin-top', 2);
            @include gutter('margin-bottom', 2);
            text-align: center;
        }
    }

    &__tour-item {
        @include make-col-ready();
        @include make-col(4);
        @include gutter('margin-bottom', 1.5);
        //align-self: flex-start;
        @include gutter('padding-left', .5);
        @include gutter('padding-right', .5);
        text-align: center;
        position: relative;

        @include media-breakpoint-down(md) {
            @include make-col(10);
            margin-left: auto;
            margin-right: auto;
        }

        img {
            @include gutter('margin-bottom', 1);
        }

        h3 {
            font-family: $font-family-sans-serif;
            @include fontSize(18px);
            @include gutter('margin-bottom', .5);
            line-height: 1.5;
            font-weight: 600;
        }

        p {
            @include fontSize(16px);
            line-height: 1.5;
        }
    }

    &__button {
        background: color_('primary');
        color: color_('white');
        border-radius: $border-radius;
        text-align: center;
        margin: 0 auto;
        @include fontSize(18px);
        font-weight: 700;
        letter-spacing: 0.15em;
        @include gutter('padding-top', .75);
        @include gutter('padding-bottom', .75);
        @include gutter('padding-left', 1.5);
        @include gutter('padding-right', 1.5);
        transition: all 150ms $easing-cubic;

        &:hover {
            color: color_('white');
            background-color: lighten(color_('primary'), 5);
            text-decoration: none;
        }

        img {
            position: relative;
            top: -4px;
            left: 10px;
        }
    }

    &__download {
        @include gutter('padding-top', 1.25);
        //@include gutter('padding-bottom', .75);
        width: 100%;
        display: flex;
        justify-content: center;

        a {
            margin: 0 20px;
        }

    }
}
</style>
