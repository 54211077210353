<template>
	<label
		class="mdc-text-field mdc-text-field--outlined"
		:class="{ 'mdc-text-field--small': small, 'mdc-text-field--with-trailing-icon': hasPost, 'mdc-text-field--with-leading-icon': hasPre, 'mdc-text-field--no-label': !label, 'mdc-text-field--full': full, 'mdc-text-field--center': center }"
	>
		<input :type="type" :name="name" :id="id" class="mdc-text-field__input" :autocomplete="autocomplete" :placeholder="placeholder" :aria-label="label" v-bind="attrs" v-on="listeners" @input="input" :aria-labelledby="`label_${id}`" />
		<span class="mdc-notched-outline">
			<span class="mdc-notched-outline__leading"></span>
			<span class="mdc-notched-outline__notch">
				<span class="mdc-floating-label" :id="`label_${id}`" v-if="label" v-html="label"></span>
			</span>
			<span class="mdc-notched-outline__trailing"></span>
		</span>
		<!-- <i class="material-icons mdc-text-field__icon"><slot name="pre"></slot></i>
		<i class="material-icons mdc-text-field__icon"><slot name="post"></slot></i>
		<div class="mdc-line-ripple" v-if="ripple"></div>
		<label class="mdc-floating-label" :class="{ 'mdc-floating-label--float-above': attrs.value }" :for="id" v-if="label">{{ label }}</label> -->
	</label>
</template>

<script>
import { MDCTextField } from '@material/textfield'
import { v4 } from 'uuid'

export default {
	name: 'InputText',
	data: () => {
		return {
			textfield: undefined,
		}
	},
	props: {
		autocomplete: String,
		id: {
			type: String,
			default: v4(),
		},
		label: String,
		name: String,
		placeholder: String,
		type: String,
		minlength: {
			type: [Number, String],
			default: null,
		},
		maxlength: {
			type: [Number, String],
			default: null,
		},
		small: Boolean,
		ripple: Boolean,
		full: Boolean,
		center: Boolean,
	},
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: (event) => this.input(event),
			}
		},
		hasPre() {
			return Boolean(this.$slots.pre)
		},
		hasPost() {
			return Boolean(this.$slots.post)
		},
	},
	mounted() {
		this.textfield = new MDCTextField(this.$el)
		if (this.textfield && this.attrs.value) {
			this.textfield.value = this.attrs.value
		}
	},
	methods: {
		input(event) {
			if (event && event.target) {
				const target = event.target
				this.$emit('input', target.value)
			}
		}
	},
	inheritAttrs: false,
}
</script>

<style scoped lang="scss">
@import '@material/textfield/mdc-text-field';
</style>
