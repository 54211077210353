<template>
	<transition name="form">
		<div class="demo-form" :class="{'demo-form--hidden': !visible}" @click.passive="visible = false" role="dialog" :aria-hidden="!visible">
			<button class="demo-form__close" @click="visible = false">close</button>
			<div class="demo-form__container">
				<iframe id="typeform-full" allow="autoplay; encrypted-media;" src="https://form.typeform.com/to/WsOgcy" title="Request A Demo Form"></iframe>
			</div>
		</div>
	</transition>
</template>

<script>
import { bus } from '@/lib/bus'

export default {
	name: 'DemoForm',
	data: () => ({
		visible: false,
	}),
	created() {
		bus.$on('demoForm', (status) => {
			if (status != undefined) {
				this.visible = status
			} else {
				this.visible = !this.visible
			}
		})
	},
	metaInfo: () => ({
		script: [
			{
				type: 'application/javascript',
				src: 'https://embed.typeform.com/embed.js',
				async: true,
				defer: true,
			},
		],
	}),
}
</script>

<style scoped lang="scss">
#typeform-full {
	border: 0;
	height: 100%;
	width: 100%;
}
.demo-form {
	@include gutter('padding', 2);
	align-items: center;
	background-color: rgba(0,0,0, 0.3);
	backdrop-filter: blur(2px);
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	left: 0;
	opacity: 1;
	pointer-events: all;
	position: fixed;
	top: 0;
	transition: opacity 150ms ease-out;
	width: 100vw;
	z-index: 998;

	&--hidden {
		opacity: 0;
		pointer-events: none;
	}

	@include media-breakpoint-down(sm) {
		@include gutter('padding', 0.5);
		@include gutter('padding-bottom', 2);
		@include gutter('padding-top', 2);
	}

	&__container {
		@include gutter('margin', 2);
		border-radius: 10px;
		flex: 1 0 100%;
		overflow: hidden;
		width: 100%;

		@include media-breakpoint-down(sm) {
			@include gutter('margin', 0);
		}
	}

	&__close {
		@include gutter('right', 2);
		appearance: none;
		background: none;
		border: 0;
		position: absolute;
		top: 1rem;
		z-index: 999;

		@include media-breakpoint-down(sm) {
			@include gutter('right', 1);
			top: 0.5em;
		}
	}
}
</style>
