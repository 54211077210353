<template>
    <div class="how">
        <div class="how__content">
            <div class="how__content-row mb-2">
                <div class="how__content-text">
                    <h2>How It Works</h2>
                </div>
                <div class="how__content-row how__content-device mb-1">
                    <span class="device-label device-label--app">Free iOS + Android App <br> for Patients</span>
                    <span class="device-label device-label--desktop">Web-based Desktop App for Practices</span>
                     <span class="device-label device-label--mobile">Web-based Desktop App for Practices <br> Free iOS + Android App for Patients</span>
                    <img src="@/assets/images/devices_alt_bg.jpg" alt="Quick Patient on Desktop and Mobile" style="max-width: 100%; height: auto;" height="715" width="1200"/>
                </div>
                <div class="how__tour-item">
                    <img src="@/assets/images/how_1.svg" alt="Create Profile on Phone" height="120" width="68"/>
                    <h3>Patients create profiles for themselves, family members, loved ones or other caretakers.</h3>
                    <p>Patients download the Quick Patient app to their Android or iOS phone and fill out patient information before their appointment. Profiles include demographic information, insurance details (with card photos), medical histories, current medications and more.</p>
                </div>
                <div class="how__tour-item">
                    <img src="@/assets/images/how_2_qr.svg" alt="Transmit Profile from Phone" height="120" width="68"/>
                    <h3>Patients scan your unique Quick Patient QR code inside your office, outside of the office, or even via telehealth.</h3>
                    <p>When a patient visits your office, we provide you with a unique Quick Patient QR code that you can display at your intake desk, outside on a sign or window, near your parking lot, or even digitally for telehealth.  Patients just scan your code to begin the check-in process.</p>
                </div>
                <div class="how__tour-item">
                    <img src="@/assets/images/how_3.svg" alt="Receive Profile at Doctor Office" height="120" width="154"/>
                    <h3>Your front office staff will instantly and securely receive the patient profile and check-in information!</h3>
                    <p>Using the Quick Patient Dashboard, your front office staff will immediately be notified when a patient checks in. You are immediately provided with the patient profile information, COVID-19 screening results and your signed legal agreements.</p>
                </div>
            </div>
             <div class="how__content-row mb-5">
                <!-- <a href="#" class="how__button">DOWNLOAD THE APP <img src="@/assets/images/icon_phone.svg" height="25" width="25"/></a> -->
                <a href="https://5inline.typeform.com/to/WsOgcy" @click.prevent="bus.$emit('demoForm')" title="Form opens in Popup" class="how__button">REQUEST A DEMO FOR YOUR PRACTICE</a>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from '@/lib/bus'

export default {
	name: 'HomeHowDoctors',
	data: () => ({
		bus,
	}),
}
</script>

<style scoped lang="scss">
.how {
    @include gutter('padding-top', 1);
    @include gutter('padding-bottom', 2);
    background-color: color_('background');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-down(sm) {
		@include gutter('padding-top', 0);
        @include gutter('padding-bottom', 1);
    }

    &__content {
        @include make-container();
        @include make-container-max-widths;

        &-row {
            @include make-row();
            @include gutter('margin-bottom', 2);
            align-items: flex-start;

            &--no-margin {
                @include gutter('margin-bottom', 0);
            }
        }

        &-text {
            @include make-col-ready();
            @include make-col(12);
            @include gutter('margin-top', 2);
            @include gutter('margin-bottom', 2);
            text-align: center;
        }

        &-device {
            position: relative;
            margin: 0 auto;

            .device-label {
                @include fontSize(18px);
                font-family: $headings-font-family;
                text-align: center;
                line-height: 1.3;
                position: absolute;

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                &--app {
                    left: 2.5%;
                    top: -15px;

                    // @include media-breakpoint-down(md) {
                    //     left: 0%;
                    // }
                }

                &--desktop {
                    //left: 350%;
                    width: 100%;
                    top: -40px;
                }

                &--mobile {
                    display: none;

                    @include media-breakpoint-down(sm) {
                        display: block;
                        width: 100%;
                        top: -60px;
                    }
                }
            }
        }
    }

    &__tour-item {
        @include make-col-ready();
        @include make-col(4);
        @include gutter('margin-bottom', 1.5);
        //align-self: flex-start;
        @include gutter('padding-left', .5);
        @include gutter('padding-right', .5);
        text-align: center;
        position: relative;

        @include media-breakpoint-down(md) {
            @include make-col(10);
            margin-left: auto;
            margin-right: auto;
        }

        &:nth-of-type(4):after,
        &:nth-of-type(4):before {
            background-size: 10px 4px;
            background-image: url('../../assets/images/how_progress.png');
            background-repeat: repeat-x;
            width: 240px;
            height: 4px;
            display: block;
            position: absolute;
            content: "";
            top: 50px;
            left: 300px;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &:nth-of-type(4):before {
            left: auto;
            right: 300px;
        }

        img {
            @include gutter('margin-bottom', .75);
        }

        h3 {
            font-family: $font-family-sans-serif;
            @include fontSize(18px);
            @include gutter('margin-bottom', .5);
            line-height: 1.5;
            font-weight: 600;
        }

        p {
            @include fontSize(16px);
            line-height: 1.5;
        }
    }

    &__button {
        background: color_('primary');
        color: color_('white');
		display: inline-block;
        border-radius: $border-radius;
        text-align: center;
        margin: 0 auto;
        @include fontSize(18px);
        font-weight: 700;
        letter-spacing: 0.15em;
        @include gutter('padding-top', .75);
        @include gutter('padding-bottom', .75);
        @include gutter('padding-left', 1.5);
        @include gutter('padding-right', 1.5);
        transition: all 150ms $easing-cubic;

        &:hover {
            color: color_('white');
            background-color: lighten(color_('primary'), 5);
            text-decoration: none;
        }

        img {
            position: relative;
            top: -4px;
            left: 10px;
        }
    }
}
</style>
