<template>
    <div class="faq">
        <div class="faq__content">
            <h2 class="text-center mb-5">Frequently Asked Questions</h2>

			<div class="faq__section" v-for="(section, key) in faqs" :key="key">
				<h5 class="faq__section-heading">{{section.heading}}</h5>
				<dl class="faq__section-content">
					<template v-for="(item, i) in section.content">
						<dt class="faq__item-q" :key="`q${i}`" :class="{'faq__item-q--active':isOpen(`${key}_${i}`)}" @click="toggle(`${key}_${i}`)">
							<span>{{item.q}}</span>
							<span class="material-icons" v-html="isOpen(`${key}_${i}`) ? 'remove' : 'add'"></span>
						</dt>
						<dd class="faq__item-a" :key="`a${i}`" :class="{'faq__item-a--active':isOpen(`${key}_${i}`)}">{{item.a}}</dd>
					</template>
				</dl>
			</div>

			<!-- <a class="btn-primary faq__button" href="https://5inline.typeform.com/to/WsOgcy" @click.prevent="bus.$emit('demoForm')" title="Form opens in Popup">REQUEST A DEMO</a> -->

        </div>
    </div>
</template>

<script>
import faqs from '@/assets/faqs.json'
import { bus } from '@/lib/bus'

export default {
	name: 'Faq',
	data: () => ({
		faqs,
		bus,
		open: [],
	}),
	methods: {
		toggle(key) {
			const idx = this.open.indexOf(key)
			if (idx > -1) {
				this.open.splice(idx, 1)
			} else {
				this.open.push(key)
			}
		},
		isOpen(key) {
			return this.open.indexOf(key) > -1
		},
	},
};
</script>

<style scoped lang="scss">
.faq {
    @include gutter('padding-top', 4);
    @include gutter('padding-bottom', 4);
    //background-color: color_('primary');
    background-color: color_('white');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //color: color_('white');

    @include media-breakpoint-down(sm) {
        @include gutter('padding-bottom', 1);
    }

    &__content {
        @include make-container();
        @include make-container-max-widths;

        &-row {
            @include make-row();
            @include gutter('margin-bottom', 2);
            align-items: center;
        }
    }

	&__section {
		@include gutter('margin-top');
        @include gutter('padding-bottom');

		&-heading {
            text-align: center;
             color: color_('secondary');
		}
	}

	&__button {
		display: inline-block;
		margin-left: 50%;
		transform: translateX(-50%);

		@include media-breakpoint-down(sm) {
			display: block;
			margin-left: auto;
			transform: none;
		}
	}

	&__item {
		&-q {
            @include gutter('padding-bottom', .6);
            @include gutter('padding-top', .2);
			align-items: center;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			font-size: $h6-font-size;
			font-family: $font-family-base;
			font-weight: 600;
			justify-content: space-between;
			line-height: 1.2em;

			.material-icons {
                font-size: 2rem;
                color: color_('primary');
			}
		}

		&-a {
			@extend .border-bottom;
			max-height: 0;
			overflow: hidden;

			&--active {
				@include gutter('padding-bottom', .6);
				max-height: 300px;
			}
		}
	}

    .faqs {
        @extend .border-bottom;
        @include gutter('padding-bottom', .6);
        @include gutter('padding-top', .6);

        &-header {
            button {
                font-size: $h6-font-size * 1.3;
                font-family: $font-family-base;
                font-weight: 600;
                width: 100%;
                text-align: left;
                color: color_('white');
                padding: 0;
                position: relative;
                white-space: normal;
                @include gutter('padding-right', 3);
                line-height: 1.3;

                &:hover {
                    //text-decoration: none;
                }

                &:after {
                    //@extend .icon;
                    content: "-";
                    font-size: $h4-font-size;
                    color: color_('white');
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    line-height: 0;
                }

                &.collapsed {
                    &:after {
                        content: "+";
                        color: color_('primary');
                    }
                }
            }
        }

        &-body {
            // @include gutter('padding-bottom', 1);
            @include gutter('padding-top', 1);
            //color: color_('text','light');
        }
    }
}
</style>
