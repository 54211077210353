<template>
    <div class="hero hero--home" role="banner">
        <div class="hero__content">
            <div class="hero__content-row">
                <div class="hero__text">
                    <!-- <span class="hero__text-leading">Safety. Security. Convenience. Accuracy.</span> -->
                    <h1 class="hero__text-heading">
						Safe &amp; Convenient Contactless Check-In!
                    </h1>
					<h2 class="hero__text-subheading">
						Instantly check in &amp; skip new patient forms with Quick Patient.
					</h2>
                    <p>Securely transmit patient data, insurance information, and sensitive personal information to your new or existing healthcare provider without touching a pen, paper or tablet - all from the convenience and safety of your own phone.</p>
                    <p>Never fill out a new patient form ever again!</p>
                    <div class="hero__text-actions pt-4">
                        <!-- <a class="btn-primary" href="https://5inline.typeform.com/to/WsOgcy" @click.prevent="bus.$emit('demoForm')" title="Form opens in Popup">REQUEST A DEMO</a> -->
                        <a href="https://play.google.com/store/apps/details?id=com.quickpatient.mobile" target="_blank"><img src="@/assets/images/btn_play.svg" width="232" height="58" alt="Download for Android" /></a>
                        <a href="https://apps.apple.com/us/app/quick-patient/id1537428688" target="_blank"><img src="@/assets/images/btn_ios.svg" width="232" height="58" alt="Download for iOS" /></a>
                    </div>
                </div>
                <div class="hero__img">
                   <img src="@/assets/images/hero_product.png" width="800" height="749" alt="Quick Patient Mobile App" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from '@/lib/bus'

export default {
	name: 'HomeHero',
	data: () => ({
		bus,
	}),
}
</script>

<style scoped lang="scss">
.hero {
    @include gutter('padding-top', 7);
    @include gutter('padding-bottom', 2);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        @include gutter('padding-top', 7);
        @include gutter('padding-bottom', 2);
    }

    @include media-breakpoint-down(sm) {
        @include gutter('padding-top', 7);
        @include gutter('padding-bottom', 0);
    }

    &__content {
        @include make-container();
        @include make-container-max-widths;

        &-row {
            @include make-row();
            align-items: center;
        }
    }

    &__img {
        // text-align: right;
        // @include make-col-ready();
        // display: none;
        // align-self: flex-end;

        // @include media-breakpoint-up(md) {
        //     @include make-col(5);
        //     display: block;
        // }

        // img {
        //     max-width: 100%;
        //     height: auto;
        // }

        position: absolute;
        left: 52%;
        bottom: 0;

        @include media-breakpoint-down(md) {
            bottom: auto;
            top: 20%;
        }

         @include media-breakpoint-down(sm) {
            top: auto;
            left: auto;
            //transform: translateX(-50%);
            //bottom: -200px;
            position: relative;
            width: 100%;

            img {
                max-width: 100%;
                height: auto;
                margin: 0 auto;
                display: block;
            }
        }
    }

    &__text {
        @include make-col-ready();
        @include gutter('margin-bottom', 2);
        text-align: center;
        font-family: $font-family-base;
        //color: color_('white');

        @include media-breakpoint-up(sm) {
            @include make-col(12);
        }

        @include media-breakpoint-up(md) {
            @include make-col(7);
            text-align: left;
        }

        .btn {
            font-weight: bold;
        }

		&-leading {
            @include gutter('margin-bottom', 0.5);
            display: block;
            font-size: $font-size-base;
            font-weight: 500;
            letter-spacing: 0.05em;
		}

        &-subheading {
            @include gutter('margin-bottom', 0.75);
            display: block;
            font-size: $font-size-base * 2.2;
            font-weight: 500;
            //letter-spacing: 0.05em;
        }

        &-heading {
            font-weight: 600;
            font-size: $font-size-base * 3.4;
            font-family: $headings-font-family;

            @include gutter('margin-bottom', .75);

            @include media-breakpoint-down(md) {
                font-size: $font-size-base * 3;
            }

            @include media-breakpoint-down(sm) {
                font-size: $font-size-base * 2.4;
                @include gutter('margin-bottom', .5);
            }
        }

        &-actions {
            @include gutter('margin-top', 1.25);

            img {
                @include gutter('margin-right', 1);
                position: relative;
                top: 0;
                transition: top 200ms $easing-cubic;

                @include media-breakpoint-down(sm) {
                    @include gutter('margin-bottom', .5);
                }

                &:hover {
                    top: -5px;
                }
            }

            a {

            }
        }

        p {
            @include gutter('padding-right', 1);
        }

        &--status {
            position: relative;
            top: -50px;
            height: 0;

            @include media-breakpoint-down(sm) {
                height: auto;
                top: 0;
            }

            span {
                border-radius: 6px;
                font-size: $font-size-sm;
                background: color_('primary');
                padding: 5px 10px;
                color: color_('white');
                margin-right: 5px;
            }
        }
    }

    &--home {
        background-image: url('../../assets/images/hero_bg_2.jpg');
        background-color: color_('background');
        background-position: center;
    }
}
</style>
