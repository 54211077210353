<template>
    <div class="why why--home">
        <div class="why__content">
            <div class="why__content-row">
                <div class="why__text">
                    <h1 class="why__text-heading">
						Improve office efficiency & see more patients!
                    </h1>
                    <p>We believe the current office check-in process is outdated and inconvenient. Now more than ever, we believe that patients want to feel safe and secure.</p>
                    <p>Quick Patient’s unique approach will become a valuable tool for modernizing office strategies and safety while increasing efficiency, revenue, and overall patient satisfaction!</p>
                    <div class="why__text-actions pt-4">
                        <a class="btn-secondary" href="https://5inline.typeform.com/to/WsOgcy" @click.prevent="bus.$emit('demoForm')" title="Form opens in Popup">REQUEST A DEMO FOR YOUR PRACTICE</a>
                        <!-- <a href="#" target="_blank"><img src="@/assets/images/btn_play.svg" width="232" height="58" alt="Download for Android" /></a>
                        <a href="#" target="_blank"><img src="@/assets/images/btn_ios.svg" width="232" height="58" alt="Download for iOS" /></a> -->
                    </div>
                </div>
                <div class="why__img">
                   <img src="@/assets/images/hero_hand.png" width="478" height="673" alt="Quick Patient Mobile App" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from '@/lib/bus'

export default {
	name: 'HomeWhy',
	data: () => ({
		bus,
	}),
}
</script>

<style scoped lang="scss">
.why {
    @include gutter('padding-top', 2);
    background-image: url('../../assets/images/hero_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-down(sm) {
        @include gutter('padding-top', 4);
        @include gutter('padding-bottom', 4);
    }

    &__content {
        @include make-container();
        @include make-container-max-widths;

        &-row {
            @include make-row();
            align-items: center;
        }
    }

    &__img {
        text-align: right;
        @include make-col-ready();
        display: none;
        align-self: flex-end;

        @include media-breakpoint-up(md) {
            @include make-col(5);
            display: block;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__text {
        @include make-col-ready();
        @include gutter('margin-bottom', 2);
        text-align: center;
        font-family: $font-family-base;
        color: color_('white');

        @include media-breakpoint-up(sm) {
            @include make-col(12);
        }

        @include media-breakpoint-up(md) {
            @include make-col(7);
            text-align: left;
        }

        .btn {
            font-weight: bold;
        }

		&-leading {
            @include gutter('margin-bottom', 0.5);
            display: block;
            font-size: $font-size-base;
            font-weight: 500;
            letter-spacing: 0.05em;
		}

        &-subheading {
            @include gutter('margin-bottom', 0.5);
            display: block;
            font-size: $font-size-base * 2.2;
            font-weight: 500;
            letter-spacing: 0.05em;
        }

        &-heading {
            font-weight: 600;
            font-size: $font-size-base * 3.4;
            font-family: $headings-font-family;
			letter-spacing: 1.5px;;

            @include gutter('margin-bottom', .75);

            @include media-breakpoint-down(md) {
                font-size: $font-size-base * 3;
            }

            @include media-breakpoint-down(sm) {
                font-size: $font-size-base * 2.4;
                @include gutter('margin-bottom', .5);
            }
        }

        &-actions {
            @include gutter('margin-top', 1.25);

            img {
                @include gutter('margin-right', 1);
                position: relative;
                top: 0;
                transition: top 200ms $easing-cubic;

                @include media-breakpoint-down(sm) {
                    @include gutter('margin-bottom', .5);
                }

                &:hover {
                    top: -5px;
                }
            }

            a {

            }
        }

        p {
            @include gutter('padding-right', 1);
        }

        &--status {
            position: relative;
            top: -50px;
            height: 0;

            @include media-breakpoint-down(sm) {
                height: auto;
                top: 0;
            }

            span {
                border-radius: 6px;
                font-size: $font-size-sm;
                background: color_('primary');
                padding: 5px 10px;
                color: color_('white');
                margin-right: 5px;
            }
        }
    }
}
</style>
