<template>
	<form class="home-login" @submit.prevent="$emit('onSubmit', fields)">
		<input-text type="password" label="Password" v-model="fields.pw" />
			<div class="mdc-touch-target-wrapper">
			<button class="mdc-button mdc-button--touch mdc-button--outlined">
				<div class="mdc-button__ripple"></div>
				<span class="mdc-button__label">Access</span>
				<div class="mdc-button__touch"></div>
			</button>
			</div>
	</form>
</template>

<script>
import InputText from '@/components/InputText'

export default {
	name: 'HomeLogin',
	components: {
		InputText,
	},
	data: () => ({
		fields: {},
	}),
}
</script>

<style scoped lang="scss">
@import "@material/button/mdc-button";

.home-login {
	@include gutter('margin');
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
</style>
