<template>
    <div class="patients">
        <div class="patients__content">
            <div class="patients__content-row">
				<h2 class="patients__text-heading">For Patients</h2><br>
            </div>
            <div class="patients__content-row patients__content-row--negative-margin">
                <div class="patients__img">
                    <img src="@/assets/images/forpatients_2.jpg" width="600" height="764" alt="Quick Patient Mobile App for Patients" />
                </div>
                <div class="patients__benefits">
                    <div class="patients__benefits-benefit">
                        <img src="@/assets/images/icon_transmit.svg" alt="Secure" height="25" width="25"/>
                        <h3>Contactless and curbside check-in for maintaining your safety</h3>
						<p>With the ability to check in for your appointments from outside of your doctor's office, even from your car, you can  ensure your safety during these trying times. No need to touch a gross pen or sticky tablet! Your information is safely and securely sent directly to your physician without having to go inside.</p>
                        <!-- <h3>Safely transmit patient &amp; insurance data to your healthcare provider</h3>
                        <p>Your information is safely and security sent directly to your Doctor's office when you check-in, without needing to touch a gross pen or sticky tablet! Store photos of your license and insurance cards so you never have to dig them out of your wallet or bag (or left at home).</p> -->
                    </div>
                    <div class="patients__benefits-benefit">
                        <img src="@/assets/images/icon_form.svg" alt="Secure" height="25" width="25"/>
                        <h3>Securely store your family's patient &amp; insurance data on your mobile device</h3>
                        <p>With our support for multiple profiles, you can keep your family's patient information with you. Store profiles for your kids, spouse, grandparents or whomever you may visit a doctor's office with. No need to remember medications, procedures or social security numbers for each visit.</p>
                    </div>
                    <div class="patients__benefits-benefit">
                        <img src="@/assets/images/icon_clock.svg" alt="Secure" height="25" width="25"/>
                        <h3>Check in to appointments fast &amp; never fill out a new patient form again!</h3>
                        <p>Fill out you and your family's patient information on your own time, at home or on the go, using the <a href="https://getqp.app">Quick Patient mobile app</a>. Arrive at your doctor's office, scan the Quick Patient QR code and you're done!</p>
                    </div>
                    <div class="patients__benefits-benefit">
                        <img src="@/assets/images/icon_history.svg" alt="Secure" height="25" width="25"/>
                        <h3>Keep your family's medical history &amp; insurance info up to date</h3>
                        <p>Update your profile information when there is a new medication, procedure, insurance or address change. You'll always have the latest and most accurate information for your doctor during your next visit.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HomePatients',
};
</script>

<style scoped lang="scss">
.patients {
    @include gutter('padding-top', 3);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-down(sm) {
		@include gutter('padding-top', 2);
        @include gutter('padding-bottom', 0);
    }

    &__content {
        @include make-container();
        @include make-container-max-widths;

        &-row {
            @include make-row();
			align-items: flex-start;

			@include media-breakpoint-up(sm) {
				@include gutter('margin-bottom', 2);
			}

			@include media-breakpoint-down(sm) {
                @include gutter('padding', .5);
            }

            &--negative-margin {
                @include media-breakpoint-up(sm) {
                    @include gutter('margin-bottom', -2);
                }
            }
        }
    }

    &__text {
        &-subheading {
            font-size: $font-size-base;
            @include gutter('margin-bottom', 0.5);
            display: block;
            @include make-col-ready();

            @include media-breakpoint-up(md) {
                @include make-col(8);
            }
        }

        &-heading {
            font-weight: 600;
            font-size: $font-size-base * 3;
            font-family: $headings-font-family;
            color: color_('primary');
            width: 100%;
            text-align: center;

			@include media-breakpoint-up(md) {
                font-size: $font-size-base * 3.4;
            }
        }
    }

    &__img {
        @include make-col-ready();
        text-align: right;

        @include media-breakpoint-up(md) {
            @include make-col(4);
        }

        img {
            display: none;

            @include media-breakpoint-up(md) {
                transform: translateX(-40%);
                display: block;
            }
        }
    }

    &__benefits {
        @include make-col-ready();
        @include gutter('padding-top', .5);
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @include media-breakpoint-up(md) {
            @include make-col(8);
            text-align: left;
        }

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        &-benefit {
            @include make-col-ready();
            @include gutter('margin-bottom', 1.5);
            align-self: flex-start;

            @include media-breakpoint-up(md) {
                @include make-col(6);
            }

            img {
                @include gutter('margin-bottom', .75);
            }

            h3 {
                font-family: $font-family-sans-serif;
                @include fontSize(20px);
                @include gutter('margin-bottom', .5);
                line-height: 1.5;
                font-weight: 600;
            }

            p {
                @include fontSize(16px);
                line-height: 1.5;
            }
        }
    }
}
</style>
